import React, { useContext } from 'react';
import slugify from 'slugify';
import { PostContext } from '../Post';

export function extractTitle(html) {
  // extract text from HTML
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  let result = doc.body.textContent;
  // trim whitespace
  result = result.trim();
  return result;
}

export function slugifyText(text) {
  return slugify(text).toLowerCase().replace(/[^a-z0-9-]/g, '');
}

export default function PageContent() {
  const post = useContext(PostContext);
  const { rewe_raw_blocks } = post;
  const headings = rewe_raw_blocks.filter((block) => block.blockName === 'core/heading');

  return (
    <ul>
      {headings.map((heading) => {
        const title = extractTitle(heading.innerHTML);
        const slug = slugifyText(title);
        return (<li key={slug}><a href={`#${slug}`}>{title}</a></li>);
      }) }
    </ul>
  );
}

import React from 'react';
import BlockInner from '../BlockInner';

export default function RawList({ innerBlocks }) {
  return (
    <ul>
      {innerBlocks.map((block) => (<BlockInner key={block.innerHTML} block={block} />))}
    </ul>
  );
}

import React from 'react';
import './style/_.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Start from './components/Start';
import Category from './components/Category';
import SinglePost from './components/SinglePost';
import GlobalContext from './GlobalContext';
import Protection from './Protection';
import NotFound from './NotFound';

function App() {
  return (
    <GlobalContext>
      <Router>
        <Protection>
          <Switch>
            <Route exact path="/" component={Start} />
            <Route exact path="/build" component={Start} />
            <Route path="/kategorie/:slug/:post" component={Category} />
            <Route path="/kategorie/:slug" component={Category} />
            <Route path="/:slug" component={SinglePost} />
            <Route component={NotFound} />
          </Switch>
        </Protection>
      </Router>
    </GlobalContext>
  );
}

export default App;

import React from 'react';
import Vimeo from './Vimeo';
import Youtube from './Youtube';

export default function Embed(props) {
  const { providerNameSlug } = props;
  if (providerNameSlug === 'youtube') {
    return <Youtube {...props} />;
  }
  if (providerNameSlug === 'vimeo') {
    return <Vimeo {...props} />;
  }
  return (
    <code>
      Unknown provider:
      {' '}
      {providerNameSlug}
    </code>
  );
}

import React from 'react';
import { Container } from 'react-bootstrap';
import Svg from './Svg';
import { getHeaderUrl } from '../global';
import useGlobalState from '../useGlobalState';
import isReweDigital from '../util/isReweDigital';

export default function Opener({
  size = 'large', section, index, title, description, secondary, inverted = false, image = null,
}) {
  const { state: { media } } = useGlobalState();

  const reweDigital = isReweDigital()
  const url = image ? media[image]?.source_url : getHeaderUrl(section, index, reweDigital);
  const style = section === 2 ? {textTransform: 'none'}  : null

  return (
    <div className={`opener ${secondary ? 'opener-secondary' : 'opener-primary'} ${inverted ? 'inverted' : ''} section-${section}`}>
      <Svg src={url} size={size} />
      <div className="overlay">
        <Container>
          <div className="title">
            <div className="inner">
              <h2 style={style}>{title}</h2>
              <p>{description}</p>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

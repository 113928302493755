import React, {
  useEffect, useState, useRef,
} from 'react';
import $ from 'jquery';
import Layout from './Layout';
import PostExpander from './PostExpander';
import Opener from './Opener';
import useCategoryBySlug from './useCategoryBySlug';
import isReweDigital from '../util/isReweDigital';

export default function Category({ match: { params: { slug, post: postSlug } } }) {
  const { posts, category, section } = useCategoryBySlug(slug);
  const { name, description } = category;
  const reweDigital = isReweDigital();

  // Scroll to element during regular navigation, as soon as its mounted
  const [ready, setReady] = useState(false);
  function handleMount(node) {
    setReady(!!node);
  }
  const [skipScroll, setSkipScroll] = useState(false);
  useEffect(() => {
    if (!ready) {
      return;
    }
    if (postSlug && postSlug !== skipScroll) {
      const nextScrollTop = $(`#${postSlug} .opener`).offset().top;
      $('html, body').scrollTop(nextScrollTop);
    }
  }, [postSlug, ready, skipScroll]);

  // Scroll to top when switching to another category index
  const postSlugRef = useRef();
  postSlugRef.current = postSlug;
  useEffect(() => {
    if (!postSlugRef.current) {
      $('html, body').scrollTop(0);
    }
  }, [slug]);

  return (
    <Layout>
      <Opener
        section={section}
        index={0}
        title={name}
        description={description}
        inverted={section !== 1 || reweDigital}
        parallax={false}
      />
      <div ref={handleMount}>
        {posts.map((post, index) => (
          <PostExpander
            key={post.id}
            section={section}
            index={index}
            post={post}
            expanded={post.slug === postSlug}
            setSkipScroll={setSkipScroll}
          />
        ))}
      </div>
    </Layout>
  );
}

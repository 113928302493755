import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import useGlobalState from '../../useGlobalState';

import Marker from '../map/Marker';
import LegendContext from './LegendContext';

function LegendEntry({
  color, name,
}) {
  return (
    <Marker color={color} legend title={name} />
  );
}

export default function Legend() {
  const { placeCategorySlug } = useContext(LegendContext);

  const placeCategorySlugs = (placeCategorySlug || '').split(',')
    .map((slug) => slug.trim())
    .filter((slug) => slug !== '');

  const { state: { placeTags } } = useGlobalState();

  const entries = placeCategorySlugs.map((slug) => placeTags[slug]).filter(Boolean);
  return (
    <Row className="legend">
      {entries.map((props) => (<Col key={props.id}><LegendEntry {...props} /></Col>))}
    </Row>
  );
}

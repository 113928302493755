import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { usePrevious, useTimeoutFn } from 'react-use';
import Submenu from './Submenu';

export default function Sublink({
  slug, activeSlug, setActiveSlug, children, isMobile, onSelect,
}) {
  const active = slug === activeSlug;
  const className = active ? 'active' : '';
  const wasActive = usePrevious(active);
  const isHiding = wasActive && !active;

  const [hiding, setHiding] = useState(false);
  const [, cancel, reset] = useTimeoutFn(() => {
    setHiding(false);
  }, 1000);

  useEffect(() => {
    if (!isHiding) {
      return;
    }
    setHiding(true);
    reset();
  }, [isHiding, reset]);

  useEffect(() => {
    if (!active) {
      return;
    }
    setHiding(false);
    cancel();
  }, [active, cancel]);

  function handleMouseEnter() {
    setActiveSlug(slug);
  }

  function handleMouseLeave() {
    setActiveSlug(null);
  }

  function handleClick() {
    $('html, body').scrollTop(0);
    onSelect();
  }

  function handleSubmenuClick() {
    setActiveSlug(slug);
  }

  const indexLink = <Link onClick={handleClick} className={className} to={`/kategorie/${slug}`}>{children}</Link>;
  const submenuLink = <Button variant="link" onClick={handleSubmenuClick}>{children}</Button>;

  return (
    <li
      className={className}
      onMouseEnter={isMobile ? null : handleMouseEnter}
      onMouseLeave={isMobile ? null : handleMouseLeave}
    >
      {isMobile ? submenuLink : indexLink}
      <Submenu slug={slug} onClick={onSelect} hiding={hiding}>
        {activeSlug && <Button variant="link" className="close-level" onClick={() => setActiveSlug(null)} />}
        {isMobile && indexLink}
      </Submenu>
    </li>
  );
}

import React from 'react';
import Raw, { filterHtml } from '../Raw';
import Image from './Image';
import Edit from '../Edit';
import Link from './Link';
import OptionalFrame from './OptionalFrame';
import Blocks from '../Blocks';

export const makeResolveItem = (state) => (item) => {
  const { links } = state;
  const { link } = item;
  if (link) {
    const element = links[link];
    if (!element) {
      return { link, title: link };
    }
    const {
      image, title, description,
    } = element.acf;
    const { link: linkUrl } = element;
    return {
      image, title, content: description, link: linkUrl, element,
    };
  }
  return item;
};

export default function Item(props) {
  const {
    image, title, content, link, caption, introBlocks,
  } = props;

  const imageId = typeof image === 'object'
    ? image.id
    : image;

  const fullCaption = [
    (introBlocks && introBlocks.length > 0) ? (<Blocks key="intro">{introBlocks}</Blocks>) : null,
    caption || null,
  ].filter(Boolean);

  return (
    <>
      <Image id={imageId} />
      {(title || content) && (
        <div className="text">
          <h3>{filterHtml(title)}</h3>
          <Raw>{content}</Raw>
          {link && <Link href={link} />}
        </div>
      )}
      {fullCaption.length > 0 ? <OptionalFrame frame>{fullCaption}</OptionalFrame> : null}
      <Edit {...props} />
    </>
  );
}

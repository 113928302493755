import React from 'react';
import { Button } from 'react-bootstrap';
import useGlobalState from '../useGlobalState';

export default function Edit({ element }) {
  const { state: { loggedIn } } = useGlobalState();
  if (!loggedIn || !element) {
    return null;
  }
  const href = `/wp-admin/post.php?post=${element.id}&action=edit`;
  return <Button className="admin-edit-link" href={href} target="_blank" rel="noreferrer">Edit</Button>;
}

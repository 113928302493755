import React from 'react';
import { getIllustrationUrl } from '../../global';

export default function Illustration({ layoutName, illustrationIndex }) {
  const src = getIllustrationUrl(layoutName, illustrationIndex);
  return (
    <div className="illustration">
      <img alt="" src={src} />
    </div>
  );
}

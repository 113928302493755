import React from 'react';
import {
  Alert, Button, Container, Form, InputGroup,
  Spinner as SpinnerFromBootstrap,
} from 'react-bootstrap';
import Layout from './components/Layout';
import Svg from './components/Svg';
import isReweDigital from './util/isReweDigital';
import useWordpressPage from './useWordpressPage';

export default function PasswordForm({
  value, message, onSubmit, loading,
}) {
  const url = `/build/images/willkommen-${isReweDigital() ? 'digital.png' : 'group.svg'}`;

  const page = useWordpressPage('passwort');

  return (
    <Layout skipNav>
      <Container className="protection mt-3">
        {page}
        {message && (
          <Alert variant="danger">
            {message}
          </Alert>
        )}
        <Form onSubmit={onSubmit} inline>
          <InputGroup className="mb-2 mr-sm-2">
            <InputGroup.Prepend>
              <InputGroup.Text>Passwort</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control type="password" name="password" defaultValue={value} />
          </InputGroup>
          {loading ? (
            <div style={{ marginTop: -5 }}>
              <SpinnerFromBootstrap animation="border" variant="primary" />
            </div>
          ) : (
            <Button className="mb-2" variant="primary" type="submit">
              Absenden
            </Button>
          )}
        </Form>
      </Container>
      <Container className="protection-image">
        <div className="mt-0 mb-4 post-expander expanded">
          <div className="opener opener-secondary">
            <Svg src={url} />
          </div>
        </div>
      </Container>

    </Layout>
  );
}

import React, { useState, useRef } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import isReweDigital from '../util/isReweDigital';

function objectify(formData) {
  return Array.from(formData.entries()).reduce((acc, cur) => {
    const [key, value] = cur;
    acc[key] = value;
    return acc;
  }, {});
}

const messagesGroup = {
  title: 'Wir freuen uns über Ihr Feedback',
  result: 'Vielen Dank - Ihre Nachricht ist eingegangen',
  error: 'Ihre Nachricht konnte nicht gesendet werden. Bitte versuchen Sie es erneut',
  fieldName: 'Ihr Name',
  fieldEmail: 'Ihre E-Mail-Adresse',
  fieldMessage: 'Schreiben Sie uns Ihre Anmerkungen und Fragen',
};

const messagesDigital = {
  title: 'Wir freuen uns über Dein Feedback',
  result: 'Vielen Dank - Deine Nachricht ist eingegangen',
  error: 'Deine Nachricht konnte nicht gesendet werden. Bitte versuche es erneut',
  fieldName: 'Dein Name',
  fieldEmail: 'Deine E-Mail-Adresse',
  fieldMessage: 'Deine Anmerkungen und Fragen',
};

const messages = isReweDigital() ? messagesDigital : messagesGroup;

function ContactForm({ onSubmit, formData = new FormData() }) {
  const formRef = useRef();
  const { name, email, message } = objectify(formData);

  function handleSubmit(event) {
    event.preventDefault();
    const _formData = new FormData(formRef.current);
    onSubmit(event, _formData);
  }

  return (
    <>
      <h2>{messages.title}</h2>
      <div className="contact">
        <Form onSubmit={handleSubmit} ref={formRef}>
          <Form.Control defaultValue={name} required name="name" type="text" placeholder={messages.fieldName} />
          <Form.Control defaultValue={email} required name="email" type="email" placeholder={messages.fieldEmail} />
          <Form.Control as="textarea" rows="3" defaultValue={message} required name="message" type="textarea" placeholder={messages.fieldMessage} />
          <Form.Control name="action" type="hidden" value="contact" />
          <Form.Control type="submit" value="Senden" />
        </Form>
      </div>
    </>
  );
}

export default function Contact() {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [formData, setFormData] = useState(new FormData());

  async function handleSubmit(event, _formData) {
    const url = `${process.env.REACT_APP_API_URL}/wp-admin/admin-ajax.php`;
    setLoading(true);
    setFormData(_formData);
    const _response = await fetch(url, {
      method: 'POST',
      body: _formData,
    }).then((result) => result.json());
    setLoading(false);
    setResponse(_response);
  }

  if (loading) {
    return (
      <Spinner variant="dark" animation="border" />
    );
  }
  if (response) {
    const { result } = response;
    if (result) {
      return <h5>{messages.result}</h5>;
    }
    return (
      <>
        <h5>{messages.error}</h5>
        <Button onClick={() => setResponse(null)}>Zurück</Button>
      </>
    );
  }
  return (
    <ContactForm onSubmit={handleSubmit} formData={formData} />
  );
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import MapContext from './MapContext';

export default function Marker({
  className = '', id, title, color, legend = false,
  setHoveredMarkerId,
}) {
  const {
    setReferenceElement, activeMarkerId, setActiveMarkerId,
  } = useContext(MapContext);
  const active = id === activeMarkerId;

  function handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
    setActiveMarkerId(id);
  }

  return (
    <div
      className={`marker ${legend ? 'legend' : ''} ${className}`}
      style={{ cursor: legend ? null : 'pointer' }}
      onClick={legend ? null : handleClick}
      onMouseEnter={() => setHoveredMarkerId(id)}
      onMouseLeave={() => setHoveredMarkerId(null)}
    >
      <div className="shadow" />
      {!legend && <div className="pulse" />}
      <div className={`pin ${legend ? '' : 'bounce'} ${active ? 'active' : ''}`} style={{ background: color }} />
      <div className="title">{title}</div>
      <div className="reference" ref={active ? setReferenceElement : null} />
    </div>
  );
}

import React from 'react';
import {
  Container, Row, Col, Button, Dropdown,
} from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import useFavs from '../../useFavs';
import useGlobalState from '../../useGlobalState';
import { makeResolveItem } from '../blocks/Item';
import Favorite from '../blocks/Favorite';
import copyTextToClipboard from '../../util/copyTextToClipboard';

export default function ({ onClose }) {
  const { favs, clear } = useFavs();
  const { state } = useGlobalState();
  const resolveItem = makeResolveItem(state);
  const items = (favs || []).map((link) => ({ link })).map(resolveItem);

  function handleTabs() {
    favs.forEach((link) => window.open(link, '_blank'));
  }

  function handleClipboard() {
    const text = favs.join('\n');
    copyTextToClipboard(text);
  }

  if (!favs || favs.length === 0) {
    return (
      <div className="favs">
        <Container>
          <Row>

            <Col>
              <h2>Noch keine Links vorhanden</h2>
              <p>
                Sie können Links dieser Liste über das
                {' '}
                <span className="icon heart-outline" />
                -Symbol hinzufügen
              </p>
            </Col>
            <Col md="auto" className="text-right col-close">
              <Button variant="link" onClick={onClose} className="close-button">
                <X size={48} onClick={onClose} />
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div className="favs">
      <Container>
        <Row>
          <Col>
            <h2>Meine Lieblingsseiten</h2>
          </Col>
          <Col md="auto" className="text-right align-self-center col-close">
            <Dropdown alignRight>
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                Alle Links
                {' '}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleTabs}>
                  In Tabs öffnen
                </Dropdown.Item>
                <Dropdown.Item onClick={handleClipboard}>
                  In die Zwischenablage kopieren
                </Dropdown.Item>
                <Dropdown.Item onClick={clear}>
                  Löschen
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button variant="link" onClick={onClose} className="close-button">
              <X size={48} onClick={onClose} />
            </Button>
          </Col>
        </Row>
      </Container>
      <Container className="list">
        <Row>
          <Col>
            {items.map((item) => <Favorite key={item.link} {...item} />)}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

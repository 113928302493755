import React from 'react';
import { Container } from 'react-bootstrap';
import Block from '../Block';

export default function Wall({ blockId, innerBlocks }) {
  return (
    <Container className="wall">
      {innerBlocks.map((innerBlock, index) => (
        <div key={`${blockId}_${index}`} className="wall-entry">
          <Block block={innerBlock} container />
        </div>
      ))}
    </Container>
  );
}

import React from 'react';
import Image from './Image';
import Blocks from '../Blocks';

function Break({ children }) {
  return (
    <>
      {children}
      <div className="break" />
    </>
  );
}

export default function Gallery({ attrs: { ids }, innerBlocks }) {
  if (!ids) {
    return <Blocks>{innerBlocks}</Blocks>;
  }

  return ids.map((id) => {
    if (id === 2481 || id === 2525 || id === 2516) {
      return (
        <Break key={id}>
          <Image key={id} id={id} />
        </Break>
      );
    }
    return (<Image key={id} id={id} />);
  });
}

import { useEffect, useRef, useState } from 'react';

export default function useWordpress(api = null) {
  const [result, setResult] = useState(null);
  const apiRef = useRef();
  apiRef.current = api;
  useEffect(() => {
    let cancelled = false;
    (async () => {
      const nextResult = apiRef.current ? await apiRef.current.get() : null;
      if (cancelled) {
        return;
      }
      setResult(nextResult);
    })();
    return function cleanup() {
      cancelled = true;
    };
  }, []);
  return result;
}

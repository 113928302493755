import React from 'react';
import { Container } from 'react-bootstrap';
import BlockInner from './BlockInner';

const slugify = (s) => s.replace('/', '-');

export default function Block(props) {
  const { block, container = true } = props;
  const { blockName } = block;
  if (!blockName) {
    return null;
  }

  if (blockName === 'lazyblock/map') {
    return (
      <div className={`${slugify(blockName).toLowerCase()}`}>
        <BlockInner {...props} />
      </div>
    );
  }

  return (
    <div className={`${slugify(blockName).toLowerCase()}`}>
      {container ? (
        <Container>
          <BlockInner {...props} />
        </Container>
      ) : (
        <BlockInner {...props} />
      )}
    </div>
  );
}

import React from 'react';
import { Container, Tooltip } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import useGlobalState from '../useGlobalState';
import OptionalOverlayTrigger from './OptionalOverlayTrigger';
import Raw from './Raw';
import useCategoryBySlug from './useCategoryBySlug';

export default function Submenu({
  slug, indexLink = null, children, onClick, hiding,
}) {
  const { posts } = useCategoryBySlug(slug);
  const { post: postSlug } = useParams();
  const { state: { isMobile } } = useGlobalState();

  const tooltipStyle = { pointerEvents: 'none' };

  return (
    <div className="submenu" style={{ pointerEvents: hiding ? 'none' : null }}>
      {children}
      <Container>
        <ul>
          {indexLink && <li>{indexLink}</li>}
          {posts.map((post) => (
            <OptionalOverlayTrigger
              key={post.slug}
              placement="bottom"
              overlay={(
                <Tooltip style={tooltipStyle}>
                  <Raw>
                    {post.excerpt.rendered}
                  </Raw>
                </Tooltip>
              )}
            >
              <li>
                <Link
                  onClick={onClick}
                  to={{
                    pathname: `/kategorie/${slug}/${post.slug}`,
                  }}
                  className={`${post.slug === postSlug ? 'active' : ''}`}
                >
                  <Raw>{post.title.rendered}</Raw>
                  {isMobile
                    && <small><Raw>{post.excerpt.rendered}</Raw></small>}
                </Link>
              </li>
            </OptionalOverlayTrigger>
          ))}
        </ul>
      </Container>
    </div>
  );
}

import React from 'react';
import * as blocks from './blocks/index';

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1).replace(/-([a-z])/g, (g) => g[1].toUpperCase());
};

const parseAttrs = (attrs) => Object.keys(attrs).reduce((acc, cur) => {
  const s = decodeURI(attrs[cur]);
  let value;
  try {
    value = JSON.parse(s);
  } catch (e) {
    value = s;
  }
  acc[cur] = value;
  return acc;
}, {});

export default function BlockInner({ block }) {
  const { blockName } = block;
  if (!blockName) {
    return null;
  }
  if (blockName === 'lazyblock/rewe-history') {
    return <blocks.ReweHistory {...block} {...parseAttrs(block.attrs)} />;
  }
  if (blockName === 'core-embed/youtube') {
    return <blocks.Youtube {...block} {...parseAttrs(block.attrs)} />;
  }
  if (blockName === 'core-embed/vimeo') {
    return <blocks.Vimeo {...block} {...parseAttrs(block.attrs)} />;
  }
  if (blockName === 'core/embed') {
    return <blocks.Embed {...block} {...parseAttrs(block.attrs)} />;
  }
  if (blockName === 'core/columns') {
    return <blocks.Columns {...block} {...parseAttrs(block.attrs)} />;
  }
  if (blockName === 'core/heading') {
    return <blocks.Heading {...block} {...parseAttrs(block.attrs)} />;
  }
  if (blockName === 'core/column') {
    return <blocks.Column {...block} {...parseAttrs(block.attrs)} />;
  }
  if (blockName === 'core/image') {
    return <blocks.Image {...block} {...parseAttrs(block.attrs)} />;
  }
  if (blockName === 'core/gallery') {
    return <blocks.Gallery {...block} {...parseAttrs(block.attrs)} />;
  }
  if (blockName === 'core/list') {
    return <blocks.RawList {...block} {...parseAttrs(block.attrs)} />;
  }
  if (blockName === 'core/list-item') {
    return <blocks.RawBlock {...block} {...parseAttrs(block.attrs)} as="li" />;
  }
  if (blockName === 'core/list-item-social') {
    return <blocks.ListItemSocial {...block} {...parseAttrs(block.attrs)} />;
  }
  const [first, last] = blockName.split('/');
  if (first === 'lazyblock') {
    const Component = blocks[capitalize(last)];
    if (!Component) {
      return <div>{`Unsupported block: ${blockName}`}</div>;
    }
    return <Component {...block} {...parseAttrs(block.attrs)} />;
  }
  if (first === 'core' || first === 'core-embed') {
    return (
      <blocks.RawBlock {...block} {...parseAttrs(block.attrs)} />
    );
  }
  return <div>{`Unsupported block: ${blockName}`}</div>;
}

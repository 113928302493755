function importAll(r) {
  return r.keys().map(r);
}

const headers = importAll(require.context('../public/header', false, /\.(jpg|svg)$/));
const quiz = importAll(require.context('../public/header', false, /\.(svg)$/));

const headerIndex0Group = [
  '1-00-Arbeitsort_group_clean',
  '2-00-REWE_group_clean',
  '3-00-Erster_Tag_group',
  '4-00-Benefiz_group_clean',
];

const headerIndex0Digital = [
  '1-00-Arbeitsort_digital_clean',
  '2-00-REWE_digital_clean',
  '3-00-Erster_Tag_digital',
  '4-00-Benefiz_digital_clean',
];

function getHeaderByPrefix(prefix, items) {
  return items.find((url) => {
    const file = url.split('/').pop();
    return file.indexOf(prefix) === 0;
  });
}

export function getHeaderUrl(section, postIndex = 0, reweDigital = false) {
  if (postIndex === 0) {
    const headersIndex0 = reweDigital ? headerIndex0Digital : headerIndex0Group;
    const prefix = headersIndex0[section - 1];
    return getHeaderByPrefix(prefix, headers);
  }

  const postNumber = String(postIndex).padStart(2, '0');

  return headers.find((url) => {
    const file = url.split('/').pop();
    const [a, b] = file.replace('_', '-').split('-');
    return a === String(section) && b === postNumber;
  });
}

export const getQuizUrl = (index) => getHeaderByPrefix(`Quiz_${String(index).padStart(2, '0')}`, quiz);

export const getIllustrationUrl = (layoutName, illustrationIndex) => {
  if (layoutName === 'nachhaltigkeit') {
    return getHeaderByPrefix(
      `Nachhaltigkeit-${String(illustrationIndex).padStart(2, '0')}`,
      importAll(require.context('../public/nachhaltigkeit', false, /\.(svg)$/)),
    );
  }
  if (layoutName === 'leitbild') {
    return getHeaderByPrefix(
      `Leitbild-${String(illustrationIndex + 1).padStart(2, '0')}`,
      importAll(require.context('../public/leitbild', false, /\.(svg)$/)),
    );
  }
  return null;
};

import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import { useEvent } from 'react-use';
import Marker from './Marker';
import OverlayView from './OverlayView';
import MapContext from './MapContext';
import Popper from './Popper';
import PlaceInfo from './PlaceInfo';
import useWordpress from '../../useWordpress';
import useParkAndRide from './useParkAndRide';
import useGlobalState from '../../useGlobalState';
import useApi from '../../useApi';

function parsePlace(place) {
  const { id, acf } = place;
  if (!acf) {
    return null;
  }
  const { lat, lng, place_id } = acf.coordinate;
  const { images } = acf;
  const content = place.content.rendered;
  const title = place.title.rendered;
  const { name: categoryName, slug: categorySlug, color } = place._embedded['wp:term'][0][0];
  return {
    id, lat, lng, place_id, content, title, categoryName, categorySlug, color, images: [images[0]],
  };
}

const identity = (place) => place;

function usePlacesData(placeCategorySlug) {
  const isPur = placeCategorySlug === 'pur';
  const api = useApi()
  const wpPlaces = useWordpress(isPur ? null : api.places().perPage(1000).embed());
  const purPlaces = useParkAndRide(isPur);

  const allPlaces = isPur ? purPlaces : wpPlaces;
  const placeCategorySlugs = (placeCategorySlug || '').split(',')
    .map((slug) => slug.trim())
    .filter((slug) => slug !== '');

  const parse = isPur ? identity : parsePlace;
  if (!allPlaces) {
    return null;
  }
  return allPlaces.map(parse)
    .filter(Boolean)
    .filter((place) => isPur || placeCategorySlugs.includes(place.categorySlug));
}

export default function Map({ placeCategorySlug = null, frame = true }) {
  const [map, setMap] = useState(null);
  const mapRef = useRef();
  mapRef.current = map;
  const [init, setInit] = useState(false);
  const [mapNode, setMapNode] = useState(null);
  const countRef = useRef();
  const [count, setCount] = useState(0);
  const placesRef = useRef();

  const [activeMarkerId, setActiveMarkerId] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const forceUpdateRef = useRef(null);

  const { state: { isMobile } } = useGlobalState();

  const places = usePlacesData(placeCategorySlug);
  placesRef.current = places;

  window.initMap = () => {
    setInit(true);
    const _map = new window.google.maps.Map(mapNode, {
      mapId: process.env.REACT_APP_MAP_ID,
      gestureHandling: 'cooperative', // auto | cooperative | none
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    });

    const maxZoom = 16;
    const bounds = new window.google.maps.LatLngBounds();
    placesRef.current.forEach((place) => {
      const { lat, lng } = place;
      if (!lat || !lng) {
        return;
      }
      bounds.extend(new window.google.maps.LatLng(lat, lng));
    });

    if (frame && !isMobile) {
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      const newSouth = ne.lat() + (sw.lat() - ne.lat()) * 2;
      bounds.extend(new window.google.maps.LatLng(newSouth, ne.lng()));
    }

    _map.panToBounds(bounds);
    _map.fitBounds(bounds);
    setTimeout(() => {
      if (_map.getZoom() > maxZoom) {
        _map.setZoom(maxZoom);
      }
    }, 1000);
    setMap(_map);
  };

  const setMapRef = useCallback((node) => {
    setMapNode(node);
    mapRef.current = node;
  }, []);

  useEffect(() => {
    if (!mapNode || !places || places.length === 0) {
      return;
    }
    if (init) {
      return;
    }
    if (window?.google?.maps?.Map) {
      window.initMap();
      return;
    }
    const id = 'google-maps-api';
    if (document.getElementById(id)) {
      return;
    }
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&v=beta&callback=initMap&v=beta&map_ids=${process.env.REACT_APP_MAP_ID}`;
    script.setAttribute('id', id);
    mapNode.appendChild(script);
  }, [mapNode, places, init]);

  const activePlace = activeMarkerId ? places.find(({ id }) => activeMarkerId === id) : null;

  countRef.current = count;
  useEffect(() => {
    const iv = setInterval(() => {
      if (!placesRef.current) {
        return;
      }
      const nextCount = countRef.current + 1;
      if (placesRef.current.length === 0) {
        return;
      }
      if (nextCount <= placesRef.current.length) {
        setCount(nextCount);
      } else {
        clearInterval(iv);
        setCount(-1);
      }
    }, 300);
    return function cleanup() {
      window.initMap = null;
      if (iv !== null) {
        clearInterval(iv);
      }
    };
  }, []);

  const hoveredMarkerIdRef = useRef(null);
  function setHoveredMarkerId(id) {
    hoveredMarkerIdRef.current = id;
  }

  const [hoveredId, setHoveredId] = useState(null);

  const handleMouseMove = useCallback(() => {
    if (hoveredId !== hoveredMarkerIdRef.current) {
      setHoveredId(hoveredMarkerIdRef.current);
    }
  }, [hoveredId, setHoveredId]);
  useEvent('mousemove', handleMouseMove);

  if (!places) {
    return null;
  }

  let googleMapsLink = null;
  if (activePlace && (placeCategorySlug !== 'laender')) {
    if (activePlace.place_id) {
      googleMapsLink = `https://www.google.com/maps/place/?q=place_id:${activePlace.place_id}`;
    } else {
      googleMapsLink = `https://www.google.com/maps/place/?q=${activePlace.lat},${activePlace.lng}`;
    }
  }

  const countingPlaces = count === -1 ? places : places.slice(0, count);

  return (
    <div className={`map-wrap map-${placeCategorySlug}`}>
      <MapContext.Provider value={{
        mapRef,
        forceUpdateRef,
        referenceElement,
        setReferenceElement,
        activeMarkerId,
        setActiveMarkerId,
      }}
      >
        {mapRef.current && (countingPlaces.map(({
          id, lat, lng, title, color,
        }) => (
          <OverlayView key={id} lat={lat} lng={lng}>
            <Marker
              id={id}
              title={title}
              color={color}
              className={id === hoveredId ? 'hover' : ''}
              setHoveredMarkerId={setHoveredMarkerId}
            />
          </OverlayView>
        )))}

        <div className="map" ref={setMapRef} />
        <Popper>
          {activeMarkerId && <PlaceInfo {...activePlace} googleMapsLink={googleMapsLink} />}
        </Popper>

      </MapContext.Provider>
    </div>
  );
}

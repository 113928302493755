import React from 'react';
import CarouselBlock from './CarouselBlock';

export default function List({ items, ...props }) {
  return (
    <div className="list">
      <CarouselBlock {...props} items={items} />
    </div>
  );
}

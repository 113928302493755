import React from 'react';
import Item, { makeResolveItem } from './Item';
import Link from './Link';
import useGlobalState from '../../useGlobalState';

export default function LinkBlock({ attrs }) {
  const {
    link, text, teaser, download,
  } = attrs;
  const { state } = useGlobalState();
  const resolveItem = makeResolveItem(state);
  let item = resolveItem({ link }) || {};
  if (!item.link) {
    if (!link) {
      return null;
    }
    item = { link };
  }
  return (
    <div className="panel">
      {teaser ? <Item {...item} /> : <Link href={link} text={text} download={download} />}
    </div>
  );
}

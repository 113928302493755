import React from 'react';
import Raw from '../Raw';
import { extractTitle, slugifyText } from './PageContent';

export default function Heading({ innerHTML }) {
  const title = extractTitle(innerHTML);
  const slug = slugifyText(title);
  return (
    <>
      <div id={slug} />
      <Raw>{innerHTML}</Raw>
    </>
  );
}

import React from 'react';
import {
  Carousel, Row, Col,
} from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import useGlobalState from '../../useGlobalState';
import Item, { makeResolveItem } from './Item';
import Blocks from '../Blocks';
import OptionalFrame from './OptionalFrame';

function arrayChunks(array, size) {
  const chunks = [];
  let index = 0;
  while (index < array.length) {
    chunks.push(array.slice(index, size + index));
    index += size;
  }
  return chunks;
}

const breakpointToCardCount = {
  xl: 3,
  lg: 3,
  md: 2,
  sm: 1,
  xs: 1,
};

function Slide({ children }) {
  return <div className="item-slide">{children}</div>;
}

function CarouselBlock({
  items, styleName = 'slider', frame = false, introBlocks = null,
}) {
  const { ref, inView } = useInView();

  let pages = [];
  const { state } = useGlobalState();
  if ((styleName === 'cards') || (styleName === 'cards-inverted')) {
    const count = Math.min(breakpointToCardCount[state.breakpoint], items.length);
    pages = arrayChunks(items, count).map((chunk) => (
      <Row className="panel-slide">
        {chunk.map((item, index) => (
          <Col className="panel" key={index} style={{ maxWidth: `${100 / count}%` }}>
            <Item {...item} imagePlaceholder />
          </Col>
        ))}
      </Row>
    ));
  } else if (styleName === 'slider') {
    pages = items.map((item) => (
      <Slide>
        <Item {...item} frame={frame} introBlocks={introBlocks} />
      </Slide>
    ));
  }

  if (pages.length === 0) {
    return '(LEER)';
  } if (pages.length === 1) {
    return pages[0];
  }

  const interval = inView ? 5000 : null;

  return (
    <div ref={ref}>
      <Carousel interval={interval}>
        {pages.map((page, index) => (
          <Carousel.Item key={index}>
            {page}
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default function (props) {
  const {
    styleName, images, frame, innerBlocks, height, className, 'hide-description': hideDescription,
  } = props;
  let { items } = props;

  if (images) {
    items = images.map(({ id, caption }) => ({ image: id, caption }));
  }
  const { state } = useGlobalState();
  const resolveItem = makeResolveItem(state);
  const resolved = items.map(resolveItem).filter(Boolean);
  return (
    <div className={`carousel-block ${styleName === 'cards-inverted' ? 'inverted' : ''} height-${height} ${hideDescription ? 'hide-description' : ''} ${className}`}>
      {(innerBlocks && innerBlocks.length > 0) && (!images) && (
        <OptionalFrame frame={frame}><Blocks>{innerBlocks}</Blocks></OptionalFrame>
      )}
      <CarouselBlock
        styleName={styleName}
        frame={frame}
        items={resolved}
        introBlocks={images ? innerBlocks : null}
      />
    </div>
  );
}

import React, { useRef, useState, useEffect } from 'react';
import parseTransform from './parseTransform';

function clamp(v, min, max) {
  if (v < min) {
    return min;
  } if (v > max) {
    return max;
  }
  return v;
}

export default function Svg({
  src, simple = undefined, ...props
}) {
  let _simple = simple;
  if (_simple === undefined) {
    _simple = src && src.indexOf('.svg') === -1;
  }

  const ref = useRef();

  const w = 1140;
  const h = 480;
  const timeTotal = 8000;

  const [time, setTime] = useState(0);
  const [layers, setLayers] = useState([]);

  /*
  const [pos, setPos] = useState(0);
  function handleScroll() {
    if (!ref.current) {
      return;
    }
    const { y, height } = ref.current.getBoundingClientRect();
    const { innerHeight } = window;
    const center = y + height / 2;
    const perc = center / innerHeight;
    setPos(perc * 2 - 1);
  }
  useEvent('scroll', parallax ? handleScroll : null, window);
  */

  const ivTick = useRef();
  const timeOffset = useRef();
  const wrapRef = useRef();

  function clearTick() {
    if (!ivTick.current) {
      return;
    }
    clearInterval(ivTick.current);
    ivTick.current = null;
  }

  function tick() {
    const _time = Date.now() - timeOffset.current;
    if (_time > timeTotal) {
      clearTick();
    }
    setTime(_time);
  }

  useEffect(() => {
    clearTick();
    setTime(0);
    return clearTick;
  }, [src]);

  function handleLoad() {
    const { contentDocument } = ref.current;
    if (!contentDocument) {
      setTimeout(handleLoad, 100);
      return;
    }
    const svg = contentDocument.querySelector('svg');
    if (!svg) {
      return;
    }
    svg.setAttribute('viewBox', `${w / 2} ${h / 2} ${w} ${h}`);
    svg.setAttribute('preserveAspectRatio', 'xMidYMid');
    svg.setAttribute('width', w);
    svg.setAttribute('height', h);
    const groups = Array.from(contentDocument.querySelectorAll('svg > g > *'));
    setLayers(groups.map((node) => {
      const transform = parseTransform(node.getAttribute('transform'));
      return { transform, node };
    }));
    // handleScroll();
    timeOffset.current = Date.now();
    ivTick.current = setInterval(tick, 1000 / 60);
  }

  useEffect(() => {
    layers.forEach(({ node, transform }, index) => {
      if (index === 0) {
        return;
      }
      const offset = (index + 1) / layers.length;
      let { y } = transform.translate || { x: 0, y: 0 };
      const { x } = transform.translate || { x: 0, y: 0 };
      const s = 1;
      const t2 = (time / timeTotal) * layers.length * 2;
      const t = clamp((t2 - (layers.length - index)) / 2, 0, 1);
      const val = t * (2 - t);
      y += ((offset) * s) * (1 - val) * h;
      node.setAttribute('transform', `translate(${x} ${y})`);
      const opacity = t;
      node.setAttribute('style', `opacity:${opacity}`);
    });
  }, [time, layers]);

  if (!src) {
    return null;
  }
  if (_simple) {
    return (
      <div className="wrap-svg mx-auto d-block">
        <img alt="" src={src} className="jpg-preview" />
      </div>
    );
  }

  return (
    <div className="wrap-svg mx-auto d-block" ref={wrapRef} style={time > 0 ? null : { opacity: 0 }}>
      <object
        {...props}
        aria-label="Grafik"
        ref={ref}
        style={{ maxWidth: w * 2, width: '100%' }}
        onLoad={handleLoad}
        data={src}
        type="image/svg+xml"
      />
    </div>
  );
}

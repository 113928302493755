import React from 'react';
import useGlobalState from '../../useGlobalState';
import Raw from '../Raw';
import Edit from '../Edit';
import RawBlock from './RawBlock';

function Placeholder() {
  return <figure className="placeholder" />;
}

export default function Image(allProps) {
  const {
    as: Component = 'img', id = null, src = null, simple = false, attrs = null, className, ...props
  } = allProps;

  const { state: { media, loggedIn } } = useGlobalState();
  const { width = null,linkDestination } = attrs || {};
  if (linkDestination === "custom") {
    return <RawBlock {...allProps} />;
  }
  if (!media) {
    return null;
  }
  let _src = src;
  let desc = null;
  let image;
  if (id) {
    image = media[id];
    if (!image) {
      return <Placeholder />;
    }
    _src = image.media_details?.sizes?.full?.source_url;
    if (!_src) {
      _src = image.source_url;
    }
    if (!_src) {
      return <Placeholder />;
    }
    desc = image.description.rendered;
    desc = desc.split('href=').join("target='_blank' rel='noopener noreferrer' href=");
  }
  if (!_src) {
    return <Placeholder />;
  }
  if (simple) {
    return <Component {...props} src={_src}  className={className} />;
  }
  const percentageWidth = width ? `${(width / 300) * 100}%` : null;
  return (
    <figure style={{ width: percentageWidth }} className={className}>
      <Component src={_src} />
      {desc && <figcaption className="image-desc"><Raw>{desc}</Raw></figcaption>}

      {loggedIn && (
      <div className="image-edit">
        <Edit element={image} />
      </div>
      )}
    </figure>
  );
}

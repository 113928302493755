import React from 'react';
import useWordpressPage from '../useWordpressPage';

const filterBlock = (block) => {
  if (block.blockName === 'core/list-item') {
    return { ...block, blockName: 'core/list-item-social' };
  }
  return block;
};

const filterBlocks = (blocks) => blocks.map((block) => ({
  ...block,
  innerBlocks: block.innerBlocks.map(filterBlock),
}));

export default function SocialIcons() {
  const page = useWordpressPage('social-links', filterBlocks);

  return <div className="social-icons">{page}</div>;
}

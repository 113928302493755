import React, { useEffect, useRef, useState } from 'react';
import { useEffectOnce, useHash } from 'react-use';
import CommonLoader from './CommonLoader';
import PasswordForm from './PasswordForm';
import makeWordpressApi from './makeWordpressApi';
import useGlobalState from './useGlobalState';
import trimChar from './util/trimChar';

export default function Protection({ children }) {
  const { setState, state: { api } } = useGlobalState();
  const apiExists = !!api;

  const [hash, setHash] = useHash();
  const hashRef = useRef(trimChar(hash, '#/'));
  useEffectOnce(() => setHash(''));

  const [loading, setLoading] = useState(false);

  const { setState: setGlobalState } = useGlobalState();

  const [wrong, setWrong] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);
      const formData = new FormData(e.target);
      const password = formData.get('password');
      const nextApi = makeWordpressApi(password);

      // this request is used to validate the password. if it fails, the catch block is executed
      const nextAudience = await nextApi.audience().get();
      setGlobalState({ student: nextAudience === 's' });

      // setting the api in the global state will display the content
      setState({ api: nextApi });
    } catch (error) {
      setWrong(true);
      setLoading(false);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  },
  [apiExists]);

  const message = wrong ? 'Leider stimmt das Passwort nicht' : null;

  if (!apiExists) {
    return (
      <PasswordForm
        value={hashRef.current}
        message={message}
        onSubmit={handleSubmit}
        loading={loading}
      />
    );
  }

  return (
    <CommonLoader>
      {children}
    </CommonLoader>
  );
}

import React from 'react';
import Block from '../Block';

export default function Column({ blockId, innerBlocks }) {
  return (
    <div className="col">
      {innerBlocks.map((innerBlock, index) => <Block key={`${blockId}_${index}`} block={innerBlock} />)}
    </div>
  );
}

import React, { createContext, useEffect } from 'react';
import $ from 'jquery';
import useGlobalState from '../useGlobalState';
import Edit from './Edit';
import Blocks from './Blocks';
import trimChar from '../util/trimChar';
import Spinner from './Spinner';

export const PostContext = createContext();

PostContext.displayName = 'PostContext';

export default function Post({
  match, post,
}) {
  const { state: { posts } } = useGlobalState();
  let _post = post;
  if (!_post && posts) {
    const { slug } = match.params;
    _post = posts[slug];
  }

  useEffect(() => {
    if (!_post) {
      return;
    }
    setTimeout(() => {
      $('a[href*="#"]')
        .click((event) => {
          const link = $(event.target);
          const href = link.attr('href') || '';
          if (trimChar(href, '#/') === '') {
            return;
          }
          const target = $(href);
          if (!target?.length) {
            return;
          }
          event.preventDefault();
          const nextScrollTop = target.offset().top - 100;
          const duration = Math.abs(nextScrollTop - document.documentElement.scrollTop) / 2;
          $('html, body').animate({ scrollTop: nextScrollTop }, { duration, easing: 'swing', queue: false });
        });
    }, 100);
  }, [_post]);

  if (!_post) {
    return <Spinner />;
  }
  return (
    <PostContext.Provider value={post}>
      <div className={`post post-id-${post.id}`}>
        <Edit element={_post} />
        <Blocks>{_post.rewe_raw_blocks}</Blocks>
      </div>
    </PostContext.Provider>
  );
}

import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Post from './Post';
import Layout from './Layout';
import CommonLoader from '../CommonLoader';
import NotFound from '../NotFound';
import Spinner from './Spinner';
import useApi from '../useApi';

export default function SinglePost({
  match,
}) {
  const [post, setPost] = useState();
  const { slug } = match.params;
  const [notFound, setNotFound] = useState(false);
  const api = useApi();

  useEffect(() => {
    (async () => {
      const posts = await api.posts().param('slug', slug).embed().get() || [null];
      if (posts.length === 0) {
        setNotFound(true);
        return;
      }
      const [_post] = posts;
      setPost(_post);
    })();
  }, [slug, api]);

  if (notFound) {
    return <NotFound />;
  }
  if (!post) {
    return 'Loading';
  }
  return (
    <CommonLoader loading={<Spinner />}>
      <Layout nav={false}>
        <Container className="p-0">
          <Post post={post} />
        </Container>
      </Layout>
    </CommonLoader>
  );
}

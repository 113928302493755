import React, { createContext } from 'react';
import { useSetState } from 'react-use';

export const Context = createContext(null);

Context.displayName = 'GlobalContext';

export default function ContextProvider({ children }) {
  const [state, setState] = useSetState({});

  return (
    <Context.Provider value={{ state, setState }}>{children}</Context.Provider>
  );
}

import React from 'react';

export default function Spotify() {
  return (
    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.6592 22.25C32.6592 17.5 19.2842 17 11.6592 19.375C10.4092 19.75 9.15921 19 8.78421 17.875C8.40921 16.625 9.15921 15.375 10.2842 15C19.1592 12.375 33.7842 12.875 43.0342 18.375C44.1592 19 44.5342 20.5 43.9092 21.625C43.2842 22.5 41.7842 22.875 40.6592 22.25ZM40.4092 29.25C39.7842 30.125 38.6592 30.5 37.7842 29.875C31.0342 25.75 20.7842 24.5 12.9092 27C11.9092 27.25 10.7842 26.75 10.5342 25.75C10.2842 24.75 10.7842 23.625 11.7842 23.375C20.9092 20.625 32.1592 22 39.9092 26.75C40.6592 27.125 41.0342 28.375 40.4092 29.25ZM37.4092 36.125C36.9092 36.875 36.0342 37.125 35.2842 36.625C29.4092 33 22.0342 32.25 13.2842 34.25C12.4092 34.5 11.6592 33.875 11.4092 33.125C11.1592 32.25 11.7842 31.5 12.5342 31.25C22.0342 29.125 30.2842 30 36.7842 34C37.6592 34.375 37.7842 35.375 37.4092 36.125ZM25.9092 0C22.6262 0 19.3753 0.646644 16.3421 1.90301C13.309 3.15938 10.553 5.00087 8.23154 7.32233C3.54313 12.0107 0.909214 18.3696 0.909214 25C0.909214 31.6304 3.54313 37.9893 8.23154 42.6777C10.553 44.9991 13.309 46.8406 16.3421 48.097C19.3753 49.3534 22.6262 50 25.9092 50C32.5396 50 38.8985 47.3661 43.5869 42.6777C48.2753 37.9893 50.9092 31.6304 50.9092 25C50.9092 21.717 50.2626 18.4661 49.0062 15.4329C47.7498 12.3998 45.9083 9.6438 43.5869 7.32233C41.2654 5.00087 38.5094 3.15938 35.4763 1.90301C32.4432 0.646644 29.1923 0 25.9092 0Z" fill="currentColor" />
    </svg>

  );
}

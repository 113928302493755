import React from 'react';
import { Container } from 'react-bootstrap';

import Layout from './components/Layout';

export default function NotFound() {
  return (
    <Layout nav={false}>
      <Container className="mt-5 mb-5">
        <h1>Diese Seite wurde nicht gefunden!</h1>
      </Container>
    </Layout>
  );
}

import { useContext, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import MapContext from './MapContext';

let markerClass;

function getMarkerClass() {
  if (markerClass) {
    return markerClass;
  }

  markerClass = class extends window.google.maps.OverlayView {
    constructor(map, latlng, div) {
      super();
      this.latlng = latlng;
      this.div = div;
      this.setMap(map);
    }

    init() {
      window.google.maps.OverlayView.preventMapHitsFrom(this.div);
      this.div.className = 'overlay-view';
      this.div.style.position = 'absolute';
      const panes = this.getPanes();
      panes.overlayMouseTarget.appendChild(this.div);
    }

    positionDiv() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
      if (point) {
        this.div.style.left = `${point.x}px`;
        this.div.style.top = `${point.y}px`;
        const { top } = this.div.getBoundingClientRect();
        this.div.style.zIndex = Math.round(top * 10);
      }
      if (this.forceUpdateRef && this.forceUpdateRef.current) {
        this.forceUpdateRef.current();
      }
    }

    draw() {
      if (!this.inited) {
        this.inited = true;
        this.init();
      }
      this.positionDiv();
    }

    remove() {
      this.div.parentNode.removeChild(this.div);
    }

    getPosition() {
      return this.latlng;
    }
  };

  return markerClass;
}

export default function OverlayView({
  children, lat, lng,
}) {
  const { mapRef, forceUpdateRef } = useContext(MapContext);

  const overlayViewRef = useRef();
  const divRef = useRef();

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }
    const MarkerClass = getMarkerClass();
    const latlng = new window.google.maps.LatLng(lat, lng);
    if (!overlayViewRef.current) {
      divRef.current = document.createElement('div');
      overlayViewRef.current = new MarkerClass(mapRef.current, latlng, divRef.current);
      overlayViewRef.current.forceUpdateRef = forceUpdateRef;
    } else {
      overlayViewRef.current.latlng = latlng;
    }
  }, [mapRef, forceUpdateRef, lat, lng, children]);

  if (!divRef.current) {
    return null;
  }
  return createPortal(children, divRef.current);
}

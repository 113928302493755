import { useEffect, useState } from 'react';

// https://gist.github.com/onderaltintas/6649521
function meters2degress({ x, y }) {
  // const lng = (x * 180) / 20037508.34;
  // const lat = (Math.atan(Math.exp((y * Math.PI) / 20037508.34)) * 360) / Math.PI - 90;
  // console.log('lat, lng', lat, lng);
  return { lat: y, lng: x };
}

function parse(data) {
  const { features } = data;
  return features.map((feature) => {
    const { geometry, attributes } = feature;
    const { lat, lng } = meters2degress(geometry);
    const {
      objectid,
      name,
      oeffnungszeiten,
      plz,
      stadt,
      stellplaetze,
      strasse,
      verbindungen,
    } = attributes;

    const content = `<p>Park und Ride Station<br/>${strasse}, ${plz} ${stadt}<br/><br/>
    ${verbindungen}<br/><br/>
    ${oeffnungszeiten}<br/><br/>
    Stellplätze: ${stellplaetze}
    `;

    return {
      id: `pur_${objectid}`, lat, lng, place_id: null, content, title: `${name}`, categoryName: 'Park & Ride', categorySlug: 'pur', color: '#064394', images: [],
    };
  });
}

// From: https://www.stadt-koeln.de/leben-in-koeln/verkehr/verkehrskalender-parken/

export default function useParkAndRide(active) {
  // const url = 'https://geoportal.stadt-koeln.de/arcgis/rest/services/WebVerkehr_DataOSM_66/MapServer/8/query?f=json&where=%20OBJECTID%20IS%20NOT%20NULL%20&returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=%7B%22xmin%22%3A739000.4022991633%2C%22ymin%22%3A6582285.098321666%2C%22xmax%22%3A816354.6749236573%2C%22ymax%22%3A6635791.018121217%2C%22spatialReference%22%3A%7B%22wkid%22%3A102100%7D%7D&geometryType=esriGeometryEnvelope&inSR=102100&outFields=STRASSE%2CPLZ%2CSTADT%2CSTELLPLAETZE%2CHYPERLINK%2COEFFNUNGSZEITEN%2CVERBINDUNGEN&outSR=102100';
  const url = 'https://geoportal.stadt-koeln.de/arcgis/rest/services/verkehr/verkehrskalender/MapServer/8/query?where=objectid+is+not+null&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=4326&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=pjson';
  const [result, setResult] = useState(null);
  useEffect(() => {
    let cancelled = false;
    (async () => {
      const data = active ? await fetch(url).then((response) => response.json()) : null;
      if (cancelled) {
        return;
      }
      try {
        const parsed = parse(data);
        setResult(parsed);
      } catch (error) {
        setResult([]);
      }
    })();
    return function cleanup() {
      cancelled = true;
    };
  }, [active]);
  return result;
}

import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useEffectOnce } from 'react-use';
import NotFound from './NotFound';
import Blocks from './components/Blocks';
import useApi from './useApi';

export default function useWordpressPage(slug, filterBlocksCallback = null) {
  const [result, setResult] = useState(<Spinner className="mb-3" animation="border" variant="primary" />);
  const api = useApi();

  useEffectOnce(() => {
    let cancelled = false;
    (async () => {
      const [_page] = await api.pages().slug(slug).embed().get() || [];
      if (cancelled) {
        return;
      }
      if (!_page) {
        setResult(<NotFound />);
        return;
      }
      const blocks = filterBlocksCallback ? filterBlocksCallback(_page.rewe_raw_blocks) : _page.rewe_raw_blocks;
      setResult(<Blocks container={false}>{blocks}</Blocks>);
    })();
    return function cleanup() {
      cancelled = true;
    };
  });
  return result;
}

/* eslint-disable no-restricted-syntax */
import { useEffectOnce, useWindowSize } from 'react-use';
import React, { useEffect } from 'react';

import useGlobalState from './useGlobalState';
import useApi from './useApi';
import Spinner from './components/Spinner';

export function objectByField(array, field = 'id') {
  return array.reduce((acc, cur) => {
    acc[cur[field]] = cur;
    return acc;
  }, {});
}

function isDevelopmentEnvironment() {
  return process.env.NODE_ENV === 'development';
}

function bootstrapDetectBreakpoint() {
  const breakpointNames = ['xl', 'lg', 'md', 'sm', 'xs'];
  const breakpointValues = [];
  for (const breakpointName of breakpointNames) {
    breakpointValues[breakpointName] = window.getComputedStyle(document.documentElement).getPropertyValue(`--breakpoint-${breakpointName}`);
  }
  for (const breakpointName of breakpointNames) {
    if (window.matchMedia(`(min-width: ${breakpointValues[breakpointName]})`).matches) {
      return breakpointName;
    }
  }
  return null;
}

export default function CommonLoader({ children }) {
  const cache = true;
  const api = useApi();
  const { state, setState } = useGlobalState();

  async function get(name, perPage) {
    const endpoint = api[name]().perPage(perPage).embed();
    const request = cache ? api.restCache().param('uri', endpoint.toString()) : endpoint;
    return request.get();
  }

  useEffectOnce(() => {
    (async () => {
      const result = await get('posts', 1000);
      const postsArray = (result || []).sort((a, b) => (`${a.slug}`).localeCompare(b.slug));
      const posts = objectByField(postsArray, 'slug');
      setState({ posts, postsArray });
    })();
  });

  useEffectOnce(() => {
    (async () => {
      const result = await get('links', 1000);
      const withTitleAsLink = result.map((item) => ({ ...item, link: item.title.rendered }));
      const links = objectByField(withTitleAsLink, 'link');
      setState({ links });
    })();
  });

  useEffectOnce(() => {
    (async () => {
      const result = await get('media', 1000);
      const media = objectByField(result);
      setState({ media });
    })();
  });

  useEffectOnce(() => {
    (async () => {
      const result = await get('categories', 100);
      const categories = objectByField(result, 'slug');
      setState({ categories });
    })();
  });

  useEffectOnce(() => {
    (async () => {
      const result = await get('placeTags', 100);
      const placeTags = objectByField(result, 'slug');
      setState({ placeTags });
    })();
  });

  useEffectOnce(() => {
    (async () => {
      try {
        let loggedIn;
        if (isDevelopmentEnvironment()) {
          loggedIn = false;
        } else {
          const me = await api.users().me().get();
          loggedIn = !!me;
        }
        setState({ loggedIn });
      } catch (e) {
        setState({ loggedIn: false });
      }
    })();
  });

  const { width } = useWindowSize();
  useEffect(() => {
    const breakpoint = bootstrapDetectBreakpoint();
    const isMobile = breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md';
    setState({ breakpoint, isMobile });
  }, [width, setState]);

  const {
    links, media, categories, posts, placeTags,
  } = state;

  const loaded = links && media && categories && posts && placeTags;

  if (!loaded) {
    return (
      <div className="loader">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return children;
}

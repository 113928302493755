import React from 'react';
import CustomMap from '../map/CustomMap';
import OptionalFrame from './OptionalFrame';
import Blocks from '../Blocks';
import LegendContext from './LegendContext';

export default function Map({ innerBlocks, attrs: { placeCategorySlug } }) {
  const frame = innerBlocks.length > 0;
  return (
    <LegendContext.Provider value={{ placeCategorySlug }}>
      {frame && (
        <OptionalFrame frame><Blocks>{innerBlocks}</Blocks></OptionalFrame>
      )}
      <CustomMap placeCategorySlug={placeCategorySlug} frame={frame} />
    </LegendContext.Provider>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import { useEvent } from 'react-use';
import Header from './Header';
import Footer from './Footer';
import isReweDigital from '../util/isReweDigital';

const DigitalTheme = React.lazy(() => import('./themes/DigitalTheme'));
const GroupTheme = React.lazy(() => import('./themes/GroupTheme'));

export default ({ children, fullscreen = false, skipNav = false }) => {
  const [top, setTop] = useState(true);
  const mainRef = useRef();

  function handleScroll() {
    setTop(window.scrollY === 0);
  }
  useEvent('scroll', handleScroll);

  const reweDigital = isReweDigital();

  const favicon = reweDigital ? 'favicon-digital.png' : 'favicon-group.png';
  useEffect(()=>{
    const link = document.querySelector("link[rel~='icon']");
    if (!link) {
      return
    }
    link.href = process.env.PUBLIC_URL + "/" + favicon;
  },[favicon])

  const htmlTitle = reweDigital ? 'Willkommen bei REWE digital' : 'Willkommen bei der REWE Group';
  useEffect(()=>{
    const node = document.querySelector("title");
    if (!node) {
      return
    }
    node.innerHTML = htmlTitle;
  },[htmlTitle])

  return (
    <div className={`scrolled-${top ? 'top' : 'middle'} ${reweDigital ? 'rewe-digital' : 'rewe-group'}`}>
      <React.Suspense fallback={<></>}>
        {reweDigital ? <DigitalTheme /> : <GroupTheme />}
      </React.Suspense>
      <Header skipNav={skipNav} />
      <main ref={mainRef}>
        {children}
      </main>
      {!fullscreen && <Footer />}
    </div>
  );
};

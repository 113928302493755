import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { useMeasure, usePrevious } from 'react-use';
import { useParams, useHistory } from 'react-router-dom';
import $ from 'jquery';
import Raw from './Raw';
import Post from './Post';
import Opener from './Opener';
import animate from './animate';

const speed = 2;

export default function PostExpander({
  post, section, index, expanded, setSkipScroll, somePostOpened,
}) {
  const { slug } = post;
  const { slug: categorySlug } = useParams();
  const history = useHistory();
  const previousExpanded = !!usePrevious(expanded);

  // Animating the height according to expanded
  const nodeRef = useRef();
  const [ref, { height }] = useMeasure();
  const roundedHeight = Math.round(height);
  const heightRef = useRef();
  heightRef.current = height;
  const [animating, setAnimating] = useState(false);
  const animatingRef = useRef();
  animatingRef.current = animating;

  // Lock scrolltop to element while animating
  const freezeTop = useCallback(() => {
    const { top } = nodeRef.current.getBoundingClientRect();
    const body = $('html, body');
    const navbarHeight = $('.navbar').height();
    const nextScrollTop = body.scrollTop() + top - navbarHeight;
    body.scrollTop(nextScrollTop);
    if (animatingRef.current) {
      requestAnimationFrame(freezeTop);
    }
  }, []);

  useEffect(() => {
    const target = $(`#${slug} .expander`);
    const currentHeight = target.height();
    const nextHeight = expanded ? roundedHeight : 0;
    const heightDifference = nextHeight - currentHeight;

    // When closing because another element was opened, we close without animation
    const isClosing = !expanded && previousExpanded;
    if (isClosing && somePostOpened) {
      target.css({ height: nextHeight });
      return;
    }

    if (heightDifference === 0) {
      return;
    }

    const isOpening = expanded && !previousExpanded;
    if (isOpening) {
      requestAnimationFrame(freezeTop);
    }

    // Animate to next height
    const duration = Math.abs(heightDifference) / speed;
    animate(target, { height: nextHeight }, duration, () => {
      setAnimating(false);
    });
    setAnimating(true);
  }, [slug, expanded, previousExpanded, roundedHeight, somePostOpened, freezeTop]);

  const handleToggle = useCallback(() => {
    const nextExpanded = !expanded;
    if (nextExpanded) {
      // Prevent jump-scrolling to the element
      setSkipScroll(post.slug);
    }
    const nextPath = nextExpanded
      ? `/kategorie/${categorySlug}/${post.slug}`
      : `/kategorie/${categorySlug}`;
    history.replace(nextPath);
  }, [expanded, categorySlug, post.slug, setSkipScroll, history]);

  const handleToggleFromBelow = useCallback(() => {
    handleToggle();

    // Scroll up during closing
    const body = $('html, body');
    const scrollTop = body.scrollTop() - roundedHeight;
    const duration = Math.abs(roundedHeight) / speed;
    animate(body, { scrollTop }, duration);
  }, [handleToggle, roundedHeight]);

  const { featured_media: image } = post;

  return (
    <div
      className={`post-expander ${expanded ? 'expanded' : 'collapsed'}`}
      id={post.slug}
      ref={nodeRef}
    >
      <div onClick={handleToggle}>
        <Opener
          title={<Raw>{post.title.rendered}</Raw>}
          description={<Raw>{post.excerpt.rendered}</Raw>}
          section={section}
          index={index + 1}
          image={image}
          secondary
        />
      </div>
      <div className="text-center">
        <div className="section arrow arrow-down" onClick={handleToggle} />
      </div>
      <div
        className="p-0 expander"
      >
        {(expanded || animating) && (<div className="post-wrap" ref={ref}><Post post={post} /></div>)}
      </div>
      <div className="text-center">
        <div className="expand" onClick={handleToggle} style={{ cursor: 'pointer' }}>
          <div className="arrow arrow-left" />
          <span>Los geht´s</span>
        </div>
        <div className="section arrow arrow-up" onClick={handleToggleFromBelow} />
      </div>

    </div>
  );
}

import useGlobalState from '../useGlobalState';

const slugToSectionIndex = {
  arbeitsort: 1,
  rewe: 2,
  arbeitstag: 3,
  benefits: 4,
};

const studentCategoryId = 22;
const employeeCategoryId = 23;

export default function useCategoryBySlug(slug) {
  const { state: { student, postsArray, categories } } = useGlobalState();
  const filterTagId = student ? studentCategoryId : employeeCategoryId;
  const category = (categories || {})[slug] || {};
  const section = slugToSectionIndex[slug];
  const matchingPosts = (postsArray || []).filter((post) => post.categories.includes(category.id)
      && (post.tags.length === 0 || post.tags.includes(filterTagId)));
  return { posts: matchingPosts, category, section };
}

import React from 'react';
import { Row } from 'react-bootstrap';
import Blocks from '../Blocks';

export default function Columns({ innerBlocks }) {
  return (
    <Row>
      <Blocks>{innerBlocks}</Blocks>
    </Row>
  );
}

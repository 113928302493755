import React from 'react';
import Block from '../Block';
import Illustration from './Illustration';

export default function Layout({ blockId, innerBlocks, layoutName }) {
  function mapSeparatorsToIllustrations(blocks) {
    let illustrationIndex = 0;
    let chunk = [];
    const chunks = [];
    blocks.forEach((block, index) => {
      const { blockName } = block;
      if (blockName === 'core/separator') {
        illustrationIndex += 1;
        if (chunk.length > 0) {
          chunks.push(<div key={chunks.length} className="chunk">{chunk}</div>);
        }
        chunks.push((<Illustration
          key={chunks.length}
          illustrationIndex={illustrationIndex}
          layoutName={layoutName}
        />));
        chunk = [];
      } else {
        chunk.push(<Block key={`${blockId}_${index}`} block={block} />);
      }
    });
    if (chunk.length > 0) {
      chunks.push(<div key={chunks.length} className="chunk">{chunk}</div>);
    }
    return chunks;
  }

  return (
    <div className={`layout layout-${layoutName}`}>
      {mapSeparatorsToIllustrations(innerBlocks)}
    </div>
  );
}

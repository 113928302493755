import React, { useState, useEffect } from 'react';
import {
  Navbar, Nav, Tooltip, Container,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Favorites from './Favorites';
import useFavs from '../useFavs';
import useGlobalState from '../useGlobalState';
import OptionalOverlayTrigger from './OptionalOverlayTrigger';
import Sublink from './Sublink';
import isReweDigital from '../util/isReweDigital';

export default function Header({ skipNav = false }) {
  const [expanded, setExpanded] = useState(false);
  const [activeSlug, setActiveSlug] = useState(null);

  useEffect(() => {
    if (!expanded) {
      setTimeout(() => setActiveSlug(null), 500);
    }
  }, [expanded, setActiveSlug]);

  const [showFavs, setShowFavs] = useState(false);
  const { favs } = useFavs();
  const active = favs && favs.length > 0;
  const { state: { isMobile } } = useGlobalState();

  function handleShowFavs() {
    setShowFavs(!showFavs);
  }

  function handleSelect() {
    setExpanded(false);
  }

  function handleToggle() {
    setExpanded(!expanded);
  }

  const reweDigital = isReweDigital();
  const reweGroupOrDigital = reweDigital ? 'REWE digital' : 'REWE Group';

  return (
    <div className="header fixed-top">
      <Navbar bg="white" expand="lg" fixed="top" expanded={expanded} onSelect={handleSelect} onToggle={handleToggle}>
        <Container className="p-0">
          <div className="p-0 w-100 d-flex justify-content-between">
            <Link className="logo-wrapper" to="/"><Navbar.Brand className="mr-auto logo"><span /></Navbar.Brand></Link>
            {!skipNav
            && (
            <div>
              <OptionalOverlayTrigger
                placement="bottom"
                overlay={(<Tooltip>Linkliste</Tooltip>)}
              >
                <div className={`d-lg-none icon fav-icon ${active ? 'heart' : 'heart-outline'}`} onClick={handleShowFavs} />
              </OptionalOverlayTrigger>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </div>
            )}

          </div>
          {!skipNav
          && (
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={`level-${activeSlug ? 1 : 0}`}>
              <ul>
                <Sublink onSelect={handleSelect} activeSlug={activeSlug} setActiveSlug={setActiveSlug} isMobile={isMobile} slug="rewe">{reweGroupOrDigital}</Sublink>
                <Sublink onSelect={handleSelect} activeSlug={activeSlug} setActiveSlug={setActiveSlug} isMobile={isMobile} slug="arbeitsort">{reweDigital ? 'Dein Arbeitsort' : 'Köln'}</Sublink>
                <Sublink onSelect={handleSelect} activeSlug={activeSlug} setActiveSlug={setActiveSlug} isMobile={isMobile} slug="arbeitstag">{reweDigital ? 'Dein erster Arbeitstag' : 'Der erste Arbeitstag'}</Sublink>
                <Sublink onSelect={handleSelect} activeSlug={activeSlug} setActiveSlug={setActiveSlug} isMobile={isMobile} slug="benefits">{reweDigital ? 'Deine Benefits' : 'Benefits'}</Sublink>
                <li className="position-relative d-none d-lg-flex fav-icon">
                  <OptionalOverlayTrigger
                    placement="bottom"
                    overlay={(<Tooltip>Linkliste</Tooltip>)}
                  >
                    <div className={`icon ${active ? 'heart' : 'heart-outline'}`} onClick={handleShowFavs} />
                  </OptionalOverlayTrigger>
                </li>
              </ul>
            </Nav>
          </Navbar.Collapse>
          )}
        </Container>
      </Navbar>
      {showFavs && <Favorites onClose={() => setShowFavs(false)} />}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useEffectOnce } from 'react-use';
import useWordpress from '../../useWordpress';
import { getQuizUrl } from '../../global';
import useApi from '../../useApi';

function shuffleArray(array) {
  const a = [...array];
  for (let i = a.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

function parseQuestion(item) {
  const {
    acf: {
      answer,
      choice_a: a,
      choice_b: b,
      choice_c: c,
      info,
      order,
    },
    title: { rendered: question },
  } = item;
  return {
    question, answer, info, choices: { a, b, c }, order: parseInt(order, 10),
  };
}

function Question({
  question, answer: correctAnswer, info, choices, questionIndex, total, onComplete,
}) {
  const [givenAnswer, setGivenAnswer] = useState('');
  const [answerKeys, setAnswerKeys] = useState(null);

  useEffect(() => {
    setGivenAnswer('');
  }, [questionIndex]);

  useEffectOnce(() => {
    const keys = shuffleArray(Object.keys(choices));
    setAnswerKeys(keys);
  });

  if (!answerKeys) {
    return null;
  }

  const isCorrect = givenAnswer === correctAnswer;
  const answered = givenAnswer !== '';
  const imageSrc = getQuizUrl(questionIndex + 1);

  return (
    <div className="question">
      <div className="index">
        <h5>
          Frage
          {' '}
          {questionIndex + 1}
          {' '}
          /
          {' '}
          {total}
        </h5>
      </div>
      <div className="ask">
        <h2>{question}</h2>
      </div>
      <div className="choices">
        {answerKeys.map((key) => {
          let background = null;
          if (givenAnswer !== '') {
            if (key === correctAnswer) {
              background = 'green';
            } else if (key === givenAnswer) {
              background = 'red';
            }
          }
          const text = choices[key];
          if (!text) {
            return null;
          }
          return (
            <Button
              style={{ background, cursor: answered ? 'default' : 'pointer' }}
              key={key}
              onClick={answered ? null : () => setGivenAnswer(key)}
            >
              {text}
            </Button>
          );
        })}
      </div>
      {answered && (
        <>
          <div className="resolution">
            <h3 style={{ color: isCorrect ? 'green' : 'red' }}>{isCorrect ? 'Korrekt!' : 'Leider falsch...'}</h3>
            <p>{info}</p>
          </div>
          <div className="next">
            <Button onClick={() => onComplete(isCorrect)}>Weiter</Button>
          </div>
        </>
      )}
      <div className="image">
        {imageSrc && <img src={imageSrc} alt="" />}
      </div>
    </div>
  );
}

function QuizInner() {
  const [index, setIndex] = useState(0);
  const [points, setPoints] = useState(0);
  const api = useApi();
  const questions = useWordpress(api.exams().perPage(100).embed());
  if (!questions) {
    return ('Lade...');
  }
  const unorderedItems = questions.map(parseQuestion);
  const items = unorderedItems.sort((a, b) => a.order - b.order);
  const item = items[index];

  function handleComplete(correct) {
    if (correct) {
      setPoints(points + 1);
    }
    setIndex(index + 1);
  }

  function handleReset() {
    setIndex(0);
    setPoints(0);
  }

  if (index < items.length) {
    return (
      <Question
        {...item}
        questionIndex={index}
        total={items.length}
        onComplete={handleComplete}
      />
    );
  }
  const resultImageSrc = getQuizUrl(questions.length + 1);
  return (
    <>
      <div className="results">
        <div className="image">
          {resultImageSrc && <img src={resultImageSrc} alt="" />}
        </div>
        <h2>
          Das war&apos;s schon!
        </h2>
        <h2>
          Sie haben alle
          {' '}
          {items.length}
          {' '}
          Fragen beantwortet und insgesamt
          {' '}
          {points}
          {' '}
          richtige Antworten gegeben!
        </h2>
      </div>
      <div className="next">
        <Button onClick={handleReset}>Nochmal quizzen</Button>
      </div>
    </>
  );
}

export default function Quiz() {
  return <div className="quiz"><QuizInner /></div>;
}

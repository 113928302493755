import React from 'react';
import Raw from '../../Raw';
import Twitch from './Twitch';
import Spotify from './Spotify';

const mapIcons = {
  twitter: '\u002e',
  xing: '\u002f',
  linkedin: '\u002c',
  facebook: '\u002b',
  youtube: '\u0030',
  instagram: '\ue903',
};

const mapIconsSvg = {
  spotify: Spotify,
  twitch: Twitch,
};

const filterHtml = (html) => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(html, 'text/xml');
  const item = htmlDoc.querySelector('a');
  const iconName = item.innerHTML.toLowerCase();
  const IconComponent = mapIconsSvg[iconName];

  if (IconComponent) {
    const href = item.getAttribute('href');
    const target = item.getAttribute('target');
    return (
      <li>
        <a href={href} target={target}>
          <IconComponent />
        </a>
      </li>
    );
  }
  const icon = mapIcons[iconName];
  // eslint-disable-next-line no-param-reassign
  item.innerHTML = icon || `<span style='font-family: sans-serif'>Icon not found: ${iconName}</span>`;

  return htmlDoc.documentElement.innerHTML;
};

export default function ListItemSocial({ innerHTML }) {
  const result = filterHtml(innerHTML);
  if (typeof result === 'string') {
    return <Raw as="li">{result}</Raw>;
  }
  return result;
}

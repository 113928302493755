export default function parseTransform(s) {
  const obj = {};
  if (!s) {
    return obj;
  }
  const val = s.match(/(translate|matrix|rotate|skewX|skewY|scale)\(.*?\)/g);
  if (!val) {
    return obj;
  }
  for (let i = 0, { length } = val; i < length; i += 1) {
    const item = val[i];
    const index = item.indexOf('(');
    const v = item.substring(index + 1, item.length - 1).split(/,|\s/);
    const n = item.substring(0, index);
    obj[n] = {};
    switch (n) {
      case 'translate':
      case 'scale':
        obj[n].x = +v[0] || 0;
        obj[n].y = +v[1] || 0;
        break;
      case 'rotate':
        obj[n].a = +v[0] || 0;
        obj[n].x = +v[1] || 0;
        obj[n].y = +v[2] || 0;
        break;
      case 'skewX':
      case 'skewY':
        obj[n].a = +v[0];
        break;
      case 'matrix':
        obj[n].a = +v[0] || 0;
        obj[n].b = +v[1] || 0;
        obj[n].c = +v[2] || 0;
        obj[n].d = +v[3] || 0;
        obj[n].e = +v[4] || 0;
        obj[n].f = +v[5] || 0;
        break;
      default:
        break;
    }
  }
  return obj;
}

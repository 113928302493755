import React, { useState, useContext } from 'react';
import { usePopper } from 'react-popper';
import MapContext from './MapContext';

export default function Popper({ children }) {
  const { referenceElement, forceUpdateRef, activeMarkerId } = useContext(MapContext);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes, forceUpdate } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
    ],
  });

  const pos = attributes.popper && attributes.popper['data-popper-placement'];

  forceUpdateRef.current = forceUpdate;

  if (!activeMarkerId) {
    return null;
  }
  return (
    <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className={`popover bs-popover-${pos}`}>
      <div className="arrow" ref={setArrowElement} style={styles.arrow} />
      {children}
    </div>

  );
}

import React from 'react';
import Raw from '../Raw';

export default function OptionalFrame({ frame, children }) {
  const content = Array.isArray(children)
    ? children.map((child) => {
      if (typeof (child) === 'string') {
        return <Raw key={child}>{child}</Raw>;
      }
      return child;
    })
    : children;
  return frame ? (
    <div className="frame">
      <div className="inner">
        {content}
      </div>
    </div>
  ) : children;
}

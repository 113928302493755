import React from 'react';
import Link from './Link';
import Image from './Image';
import Raw from '../Raw';

export default function Favorite(props) {
  const {
    image, title, content, link,
  } = props;
  return (
    <div className="fav">
      <Image id={image} />
      {(title || content) && (
        <div className="text">
          <h5>{title}</h5>
          <Raw>{content}</Raw>
        </div>
      )}
      <Link href={link} favMode />
    </div>
  );
}

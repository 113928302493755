import React from 'react';
import {
  Container, Col, Row,
} from 'react-bootstrap';
import { Link } from '../navigation';

import Layout from './Layout';
import Contact from './Contact';
import Svg from './Svg';
import CommonLoader from '../CommonLoader';
import Raw from './Raw';
import Spinner from './Spinner';
import useWordpressPage from '../useWordpressPage';
import isReweDigital from '../util/isReweDigital';
import SocialIcons from './SocialIcons';
import News from './News';

const filesGroup = [
  'Start_02-REWE-Group.svg',
  'Start_01-Koeln.svg',
  'Start_03-Erster-Arbeitstag.svg',
  'Start_04-Benetiz.svg',
];

const filesDigital = [
  'digital_01_company.jpg',
  'digital_02_ort.jpg',
  'digital_03_arbeitstag.jpg',
  'digital_04_benefits.jpg',
];

export default () => {
  const reweDigital = isReweDigital();

  const companyTitle = reweDigital ? 'REWE digital' : 'REWE GROUP';
  const files = reweDigital ? filesDigital : filesGroup;
  const links = {
    '/kategorie/rewe': { label: `<span style='text-transform:none'>${companyTitle}&nbsp;&nbsp;&nbsp;</span>`, file: files[0] },
    '/kategorie/arbeitsort': { label: reweDigital ? 'Dein Arbeitsort' : 'Köln', file: files[1] },
    '/kategorie/arbeitstag': { label: reweDigital ? 'Dein erster Arbeitstag' : 'Der erste Arbeitstag', file: files[2] },
    '/kategorie/benefits': { label: reweDigital ? 'Deine Benefits' : 'Benefits', file: files[3] },
  };

  const page = useWordpressPage('zertifikate');

  return (
    <>
      <CommonLoader loading={<Spinner />}>
        <Layout>
          <div className="page-start">
            <Container className="p-0">
              <Row>
                {Object.keys(links).map((link, index) => {
                  const { label, file } = links[link];
                  return (
                    <Col lg={6} key={link} className={`mt-3 start-banner p-3 ${(index === 1 && !reweDigital) ? '' : 'inverted'}`}>
                      <Link to={link}>
                        <Svg src={`build/header/${file}`} simple />
                        <div className="title"><div className="inner"><h1><Raw>{label}</Raw></h1></div></div>
                      </Link>

                    </Col>
                  );
                })}
              </Row>
              {!reweDigital && (
              <Row className="mt-3">
                <img className="p-4" alt="" src="/build/images/logos-list.svg" />
              </Row>
              )}
              <Row className="p-2">
                <Col lg className="mt-3 start-one">
                  <News />
                </Col>
                <Col className="mt-3" lg>
                  <SocialIcons />
                </Col>
              </Row>
              <Row className="p-2">
                <Col className="mt-3" lg>
                  <Contact />
                </Col>
                <Col lg className="mt-3 start-certified">
                  {page}
                </Col>
              </Row>

            </Container>
          </div>
        </Layout>
      </CommonLoader>
    </>
  );
};

import React from 'react';

export default function Twitch() {
  return (
    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M32.7261 29.7328L37.2647 25.1941V12.6768H19.0622V29.7328H24.1901V33.7141L28.1714 29.7328H32.7261ZM27.5981 16.6581H25.3208V23.4742H27.5981V16.6581ZM33.8568 16.6581H31.5794V23.4742H33.8568V16.6581Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M25.3941 50C39.2013 50 50.3941 38.8071 50.3941 25C50.3941 11.1929 39.2013 0 25.3941 0C11.587 0 0.394135 11.1929 0.394135 25C0.394135 38.8071 11.587 50 25.3941 50ZM12.2462 16.0848L17.9315 10.3995H39.5421V26.3248L29.3021 36.5647H24.7634L19.0622 42.25V36.5647H12.2462V16.0848Z" fill="currentColor" />
    </svg>

  );
}

import React from 'react';
import useGlobalState from '../../useGlobalState';
import Link from './Link';

export default function ReweHistory() {
  const { state: { isMobile } } = useGlobalState();

  const src = '/history.html';
  const srcExt = 'https://www.rewe-group.com/de/unternehmen/unternehmensgeschichte/';

  if (isMobile) {
    return (
      <div className="text-center panel">
        <Link href={srcExt} text="REWE Geschichte öffnen" />
      </div>
    );
  }

  return (
    <div className="rewe-history">
      <iframe title="REWE Geschichte" src={src} frameBorder={0} />
    </div>
  );
}

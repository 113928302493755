import React from 'react';
import flow from 'lodash/flow';

const replaceCorruptedChar = (s) => s.replace(/\u2028/g, ' ');

const filterLists = (s) => {
  if (s.indexOf('>- ') === -1) {
    return s;
  }

  // Convert a paragraph containing only single lines starting with "- " to an unordered list
  const parser = new DOMParser();
  const doc = parser.parseFromString(s, 'text/html');
  const paragraphs = Array.from(doc.querySelectorAll('p'));
  paragraphs.forEach((paragraph, index) => {
    const entries = []
    const isList = Array.from(paragraph.childNodes).every((child) => {
      if (child.nodeType !== 3) {
        return true
      }

      const edgeNode = index === 0 || index === 1 || index === paragraph.childNodes.length - 1;
      const afterBreak = child.previousSibling?.nodeName === 'BR'
      const hasBullet = child.textContent.indexOf("- ") === 0
      if (hasBullet) {
        entries.push(child.textContent.substring(2))
      }
      return (edgeNode || afterBreak) && hasBullet;
    });
  
    if (isList && entries.length > 0) {
      paragraph.innerHTML = `<ul>${entries.map(entry=>`<li>${entry}</li>`).join("")}</ul>`;
    }
  })
  return doc.documentElement.innerHTML;
};

export function filterHtml(html) {
  if (typeof (html) !== 'string') {
    return html;
  }

  return flow([replaceCorruptedChar, filterLists])(html);
}

export default function Raw({ children, as = 'span' }) {
  const Component = as;
  return (
    // eslint-disable-next-line react/no-danger
    <Component dangerouslySetInnerHTML={{ __html: filterHtml(children) }} />
  );
}

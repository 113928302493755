import useGlobalState from './useGlobalState';
import makeWordpressApi from './makeWordpressApi';

const apiWithoutPassword = makeWordpressApi(null);

export default function useApi() {
  const { state: { api: apiWithPassword } } = useGlobalState();
  const api = apiWithPassword || apiWithoutPassword;
  return api;
}

import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import useGlobalState from '../useGlobalState';

export default function OptionalOverlayTrigger(props) {
  const { state: { isMobile } } = useGlobalState();
  const { children, ...rest } = props;
  if (isMobile) {
    return children;
  }
  return <OverlayTrigger {...rest}>{children}</OverlayTrigger>;
}

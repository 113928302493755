import { useEffectOnce, useDebounce } from 'react-use';
import useGlobalState from './useGlobalState';

export default function useFavs() {
  const { state: { favs }, setState } = useGlobalState();

  useEffectOnce(() => {
    if (favs) {
      return;
    }
    let _favs;
    try {
      _favs = JSON.parse(localStorage.getItem('favs'));
      if (!Array.isArray(_favs)) {
        throw new Error('Wrong type');
      }
    } catch (e) {
      _favs = [];
    }
    setState({ favs: _favs });
  });

  useDebounce(() => {
    localStorage.setItem('favs', JSON.stringify(favs));
  }, 500, [favs]);

  function add(link) {
    setState({ favs: [...favs.filter((fav) => fav !== link), link] });
  }
  function remove(link) {
    setState({ favs: favs.filter((fav) => fav !== link) });
  }
  function clear() {
    setState({ favs: [] });
  }
  return {
    favs, add, remove, clear,
  };
}

export default function animate(target, params, duration, complete = null) {
  target.stop(true).animate(params, {
    duration, easing: 'swing',
  }).queue(() => {
    target.dequeue();
    if (complete) {
      complete();
    }
  });
  return () => target.stop(true);
}

import WPAPI from 'wpapi';

function makeWordpressApi(password) {
  const endpoint = `${process.env.REACT_APP_API_URL}/wp-json`;
  let nonce = document.querySelector('#root').getAttribute('data-nonce');
  if (nonce === '__WP_NONCE__') {
    nonce = null;
  }

  const config = {
    endpoint,
    nonce,
  };

  const wp = new WPAPI(config);

  if (password) {
    const user = 'admin';
    wp.setHeaders('Authorization', `Basic ${btoa(`${user}:${password}`)}`);
  }

  wp.menus = wp.registerRoute('menus/v1', '/menus/(?P<id>[a-zA-Z(-]+)');
  wp.places = wp.registerRoute('wp/v2', '/place/(?P<id>\\d+)');
  wp.placeTags = wp.registerRoute('wp/v2', '/place_tag/(?P<id>\\d+)');
  wp.links = wp.registerRoute('wp/v2', '/link/(?P<id>\\d+)');
  wp.exams = wp.registerRoute('wp/v2', '/exam/(?P<id>\\d+)');
  wp.restCache = wp.registerRoute('rest-cache', '/get/(?P<id>)');
  wp.audience = wp.registerRoute('rest-cache', '/audience');

  return wp;
}

export default makeWordpressApi;

import React from 'react';
import Raw from '../Raw';

function getVimeoId(url) {
  // https://vimeo.com/348655923;
  return url.split('/').pop();
}

export default function Vimeo(props) {
  const { attrs: { url }, innerHTML } = props;
  const id = getVimeoId(url);
  const src = `https://player.vimeo.com/video/${id}?title=0&amp;byline=0&amp;portrait=0&amp;dnt=0`;
  return (
    <div className="vimeo">
      <div className="iframe-wrap">
        <iframe title={`vimeo_${id}`} src={src} allow="autoplay; fullscreen" frameBorder="0" allowFullScreen="1" />
      </div>
      <Raw>{innerHTML}</Raw>
    </div>
  );
}

// &amp;app_id=122963
// <iframe src="https://player.vimeo.com/video/336812660?title=0&byline=0&portrait=0" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />;

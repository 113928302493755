import React from 'react';
import useGlobalState from '../../useGlobalState';
import CarouselBlock from './CarouselBlock';
import Intro from './Intro';

export default function Magazine({ innerBlocks }) {
  const { state: { links } } = useGlobalState();
  const matches = Object.keys(links).filter((link) => !!links[link].acf.automatic);
  const items = matches.map((link) => ({ link }));
  return (
    <div className="magazine">
      <Intro>{innerBlocks}</Intro>
      <CarouselBlock styleName="cards" items={items} />
    </div>
  );
}

import React from 'react';
import { Alert } from 'react-bootstrap';
import Blocks from '../Blocks';

export default function Warning({ innerBlocks }) {
  return (
    <Alert variant="warning">
      <Blocks>{innerBlocks}</Blocks>
    </Alert>
  );
}

import React from 'react';
import { Spinner as SpinnerFromBootstrap } from 'react-bootstrap';

export default function Spinner() {
  return (
    <div className="loader">
      <SpinnerFromBootstrap animation="border" variant="primary" />
    </div>
  );
}

import React, { useContext } from 'react';
import { Card, Button } from 'react-bootstrap';
import MapContext from './MapContext';
import Edit from '../Edit';
import Raw from '../Raw';
import { Image } from '../blocks/index';

export default function PlaceInfo(props) {
  const {
    content, title, images = [], googleMapsLink,
  } = props;
  const { setActiveMarkerId } = useContext(MapContext);
  function handleClick(e) {
    e.preventDefault();
    setActiveMarkerId(null);
  }
  return (
    <div className="place-info">
      <button type="button" className="close" onClick={handleClick}>
        <span aria-hidden="true">×</span>
        <span className="sr-only">Close</span>
      </button>
      {images.filter(Boolean).map((id) => <Image as={Card.Img} key={id} id={id} simple variant="top" />)}
      <h3><Raw>{title}</Raw></h3>
      <div className="text">
        <Raw>{content}</Raw>
      </div>
      {googleMapsLink && <Button href={googleMapsLink} rel="noopener noreferrer" target="_blank">Google Maps öffnen</Button>}
      <Edit element={props} />
    </div>
  );
}

import React from 'react';

import {
  Navbar, Container, Row, Col,
} from 'react-bootstrap';
import useWordpressPage from '../useWordpressPage';

export default function Footer() {
  const footerLinks = useWordpressPage('footer-links');
  return (
    <Navbar>
      <Container className="p-4 mt-2">
        <Row className="w-100">
          <Col md className="mb-2">© 2024 REWE Group - All rights reserved</Col>
          <Col md className="footer-links">
            {footerLinks}
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}

import React from 'react';
import Blocks from '../Blocks';

export default function Intro({ children }) {
  return (
    <div className="intro">
      <Blocks>{children}</Blocks>
    </div>
  );
}

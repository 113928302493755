import React from 'react';
import Raw from '../Raw';

function getYoutubeId(_url) {
  let url = _url;
  let id = '';
  url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    id = url[2].split(/[^0-9a-z_-]/i);
    [id] = id;
  } else {
    id = url;
  }
  return id;
}

export default function Youtube(props) {
  const { attrs: { url }, innerHTML } = props;
  const id = getYoutubeId(url);
  const autoplay = 0;
  const controls = 1;
  const modestbranding = 1;
  const src = `https://www.youtube.com/embed/${id}?controls=${controls}&amp;modestbranding=${modestbranding}&amp;autoplay=${autoplay}`;
  return (
    <div className="youtube">
      <div className="iframe-wrap">
        <iframe title={`youtube_${id}`} src={src} allow="autoplay; fullscreen" frameBorder="0" allowFullScreen="1" />
      </div>
      <Raw>{innerHTML}</Raw>
    </div>
  );
}
